<template>
  <div class="tabbar">
    <div class="fix-tabbar">
      <div class="tabbar-item" :class="current === 0 ? 'active' : ''"
           @click="toPage(0, 'benCai')">
        <img :src="require('../assets/img/ac-update.png')"
             width="100%" height="100%" v-show="current === 0">
        <img :src="require('../assets/img/update.png')"
             width="100%" height="100%" v-show="current !== 0">
      </div>
      <div class="tabbar-item" :class="current === 1 ? 'active' : ''"
           @click="toPage(1, 'gram')">
        <img :src="require('../assets/img/ac-yezi.png')"
             width="100%" height="100%" v-show="current === 1">
        <img :src="require('../assets/img/yezi.png')"
             width="100%" height="100%" v-show="current !== 1">
      </div>
      <div class="tabbar-item" :class="current === 2 ? 'active' : ''"
           @click="toPage(2, 'home')">
        <img :src="require('../assets/img/ac-home.png')"
             width="100%" height="100%" v-show="current === 2">
        <img :src="require('../assets/img/home.png')"
             width="100%" height="100%" v-show="current !== 2">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tabbar',
  props: {
    active: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      otherActive: this.active
    }
  },
  computed: {
    current () {
      return this.otherActive
    }
  },
  methods: {
    toPage (index, name) {
      this.otherActive = index
      if (this.$route.name === name) {
        return false
      }
      this.$router.replace({
        name
      })
    }
  }
}
</script>

<style scoped lang="scss">
.tabbar{
  height: 73px;
  background-color: transparent;
  .fix-tabbar{
    z-index: 2;
    position: fixed;
    bottom: 0;
    left: 18px;
    right: 18px;
    height: 73px;
    border-radius: 20px;
    background: #000000;
    padding: 21px 60px 29px;
    display: flex;
    justify-content: space-between;
    /*align-items: center;*/
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    .tabbar-item{
      width: 24px;
      height: 24px;
      position: relative;
    }
    .tabbar-item.active:after{
      display: block;
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      background: #FFFFFF;
      border-radius: 50%;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
